import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ProformaService {
  async buscarProforma(id) {
  
    const proforma = await fetchWrapper.get(`${ruta}/proforma/${id}`);
    return proforma;
  }
}